<template>
  <div class="wraper" ref="main">
    <top-nav :navColor="$route.meta.navColor" :name="$route"  @loginEmit="loginEmit"></top-nav>
    <router-view v-slot="{ Component }">
      <keep-alive>
        <component  :key="$route.path" @loginEmit="loginEmit" v-if="$route.meta.keepAlive" :is="Component" />
      </keep-alive>
    </router-view >
    <router-view  :key="$route.path" @loginEmit="loginEmit" v-if="!$route.meta.keepAlive"></router-view>
    <floot-nav :navColor="$route.meta.navColor"></floot-nav>
    <!-- 广告弹窗 -->
    <el-dialog
      :close-on-click-modal="false"
      v-if="$route.name == 'index'"
      custom-class="dialog-style"
      title=""
      top="5vh"
      :show-close="false"
      v-model= "isShowAd"
      width="1300px"
    >
      <div style="width: 1200px;position: relative;">
        <!-- <span v-if="!bannerChangeFirt" style="position: absolute;top: 0px;right: 30px;color: #fff;font-size: 30px;">{{timeshow + 's'}}</span> -->
        <el-progress class="custom-progress" @click="timeshowloading == 100 ? handleClose() : ''" stroke-width="2" color="#ff7b2b" :format="format" type="circle" :text-color="showcolor" width="40" :percentage="timeshowloading"></el-progress>
        <img  v-show="!bannerChange" src="../../assets/index/ad4.png" style="width: 85%;">
        <img v-show="bannerChange" src="../../assets/index/ad3.png" style="width: 85%;">
        <div class="change-btn" @click="handleChange">
          <i style="color: #ff7b2b;font-size: 40px;cursor: pointer;" class="el-icon-arrow-right"></i>
        </div>
        <div style="position: absolute;bottom: 20px;display: flex;justify-content: space-around;left: 50%;margin-left: -80px;">
          <div class="swiper-li" @click="!bannerChange ? handleChange() : ''" :class="{'active': bannerChange}"></div>
          <div class="swiper-li" @click="bannerChange ? handleChange() : ''" :class="{'active': !bannerChange}" style="margin-left: 40px;"></div>
        </div>
      </div>
    </el-dialog>
    <div class="ad-content">
      <img v-show="bannerChangeFoot" src="../../assets/index/ad.png" style="width: 100%;margin-bottom: -5px;"/>
      <img v-show="!bannerChangeFoot" src="../../assets/index/adf2.png" style="width: 100%;margin-bottom: -5px;"/>
      <div class="change-btn" style="right: 10%;" @click="bannerChangeFtoor">
        <i style="color: #ff7b2b;font-size: 40px;cursor: pointer;" class="el-icon-arrow-right"></i>
      </div>
    </div>
    <el-backtop target=".wraper" right="40" bottom="40"></el-backtop>
    <div class="toTop-content" :class="{'active': isShrink}">
      <div class="toTop" :class="{'active': isShrink}">
        <el-popover
          placement="left"
          trigger="hover"
          :width="100"
        >
          <template #reference>
            <div class="span-to">
              <span class="icon iconfont" style="font-size: 25px;height: 0%;margin-top: -10px;">&#xe81e;</span>
              <span style="font-size: 13px;height: 20%;margin-top: -20px;">微信扫一扫</span>
            </div>
          </template>
          <el-image fit="fill" style="height: 150px;width: 150px;" draggable="false" :src="engineering"></el-image>
          <div style="text-align: center;">微信扫一扫查看</div>
        </el-popover>
        <el-popover
          placement="left"
          :width="80"
          trigger="hover"
        >
          <template #reference>
            <div class="span-to">
              <span class="icon iconfont" style="font-size: 30px;height: 0%;margin-top: -10px;width: 100%;">&#xe8bb;</span>
              <span style="font-size: 13px;height: 20%;margin-top: -20px;width: 100%;">公众号</span>
            </div>
          </template>
          <el-image fit="contain" style="height: 150px;width: 150px;" draggable="false" :src="account"></el-image>
          <div style="text-align: center;">关注易工程公众号</div>
        </el-popover>
        <el-popover
          placement="left"
          :width="100"
          trigger="hover"
        >
          <template #reference>
            <div class="span-to">
              <span class="icon iconfont phone" style="font-size: 25px;height: 0%;margin-top: -10px;width: 100%;">&#xe66b;</span>
              <span style="font-size: 13px;height: 20%;margin-top: -20px;width: 100%;">客服电话</span>
            </div>
          </template>
          <div>电话：{{ myCustomerServiceTel }}</div>
        </el-popover>
        <span @click="handleTop" class="icon iconfont span-to" style="border-bottom: none;">&#xe63f;</span>
      </div>
      <i class="el-icon-d-arrow-right" :class="{'active': isShrink}"  @click="handleShrink" :title="isShrink ? '展开' : '收缩'" style="position: absolute;left: -20px;top: 50%;color: #e6a23c;font-size: 20px;font-weight: 600;cursor: pointer;margin-top: -10px;"></i>
    </div>
    <el-dialog
      title=""
      @opened="loginFormOpened"
      @close="loginFormClose"
      :show-Close="false"
      v-model= "isShowLogin"
      width="768px"
      >
      <login-form ref="loginFormRef" :pathName="pathName" @closeLoginEmit="closeLoginEmit"></login-form>
    </el-dialog>
    <!-- 遮罩层广告 -->
    <!-- <el-dialog v-model="dialogVisible" :show-close="false" fullscreen close-on-click-modal destroy-on-close center custom-class="ggClass">
      <template #footer>
        <div style="text-align: center;position: relative;">
          <i class="el-icon-circle-close" style="font-size: 20px;color: #fff;position: absolute;top: -10px;right: 38%;cursor: pointer;z-index: 9;" @click="closeGg"></i>
          <el-image @click="handleOpen" fit="contain" draggable="false" style="width:22%;max-height: 50vw;" :src="ggImage"></el-image>
        </div>
      </template>
    </el-dialog> -->
  </div>
</template>

<script>
import topNav from '@/components/topNav/topNav.vue'
import flootNav from '@/components/flootNav/flootNav.vue'
import LoginForm from '@/components/login-form/login-form'
import { ref } from 'vue'
import { mapGetters } from 'vuex'
import { refreshToken } from '@/api/user.js'
import { getDictByModel } from '@/api/dict'
export default {
  name: 'home',
  components: {
    topNav,
    flootNav,
    LoginForm
  },
  data () {
    return {
      bannerChangeFoot: false,
      bannerChange: true,
      changeTime: '',
      changeTimeFoot: '',
      bannerChangeFirt: false,
      bg: require('../../assets/index/bg.png'),
      engineering: require('../../assets/index/e.png'),
      account: require('../../assets/index/g.png'),
      ggImage: require('../../assets/index/gg.png'),
      myCustomerServiceTel: '',
      // isShowLogin: false
      isShrink: false,
      dialogVisible: true,
      isShowAd: true,
      timeshow: 5,
      timeshowloading: 0,
      showcolor: '#ffffff'
    }
  },
  computed: {
    ...mapGetters([
      'vipData', // 会员数据
      'idToken',
      'userInfo',
      'customerServiceTel'
    ]),
    customerServiceTelChange: function () {
      const vm = this
      if (vm.customerServiceTel) {
        return vm.customerServiceTel
      } else {
        return null
      }
    }
  },
  watch: {
    customerServiceTelChange: {
      immediate: true,
      handler: function (newValue, oldValue) {
        this.myCustomerServiceTel = newValue
      }
    },
    $route: {
      handler (val, oldval) {
        if (val.name !== 'index') {
          this.isShowAd = false
        }
      }
    }
  },
  mounted () {
    const vm = this
    setInterval(function () {
      if (vm.idToken) {
        refreshToken({}).then(result => {
          if (result.code === 0) {
            const idToken = result?.data?.data?.refreshIdToken
            console.log(idToken)
            if (idToken) {
              vm.$store.dispatch('setIdToken', idToken)
            }
          }
        }).catch(error => {
          console.log(error)
        })
      }
    }, 10 * 60 * 1000)
    vm.changeTime = setInterval(function () {
      vm.bannerChange = !vm.bannerChange
      vm.bannerChangeFirt = true
    }, 5000)
    vm.changeTimeshow = setInterval(function () {
      if (vm.timeshow > 0) {
        vm.timeshow = vm.timeshow - 1
        vm.timeshowloading = vm.timeshowloading + 20
      } else {
        clearInterval(vm.changeTimeshow)
      }
    }, 1000)
    vm.changeTimeFoot = setInterval(function () {
      vm.bannerChangeFoot = !vm.bannerChangeFoot
    }, 5000)
    setInterval(function () {
      // console.log('home: ' + JSON.parse(window.localStorage.getItem('vipData')).vipLevel)
      vm.$store.dispatch('setVipData', window.localStorage.getItem('vipData'))
    }, 1 * 1000)
    setInterval(function () {
      // 隐藏标签
      const elPagers = document.getElementsByClassName('el-pager')
      if (elPagers) {
        for (let i = 0; i < elPagers.length; i++) {
          const children = elPagers[i].children
          if (children) {
            for (let j = 0; j < children.length; j++) {
              if (j === children.length - 2 && children[j].className === 'el-icon more btn-quicknext el-icon-more') {
                elPagers[i].children[children.length - 1].style.display = 'none'
                elPagers[i].children[children.length - 2].style.display = 'none'
              } else if (j === children.length - 2 && children[j].className !== 'el-icon more btn-quicknext el-icon-more') {
                elPagers[i].children[children.length - 1].style.display = ''
                elPagers[i].children[children.length - 2].style.display = ''
              }
              if (j === 1 && children[j].className === 'el-icon more btn-quickprev el-icon-more') {
                elPagers[i].children[1].style.display = 'none'
                elPagers[i].children[0].style.display = 'none'
              } else if (j === 1 && children[j].className !== 'el-icon more btn-quickprev el-icon-more') {
                elPagers[i].children[1].style.display = ''
                elPagers[i].children[0].style.display = ''
              }
            }
          }
        }
      }
    }, 200)
    const data = {
      dictCodeLike: '0006'
    }
    getDictByModel(data).then(result => {
      if (result.code === 0) {
        const data = result.data ? result.data : []
        for (let i = 0; i < data.length; i++) {
          if (data[i].dictNo !== '0006') {
            vm.$store.dispatch('setCustomerServiceTel', data[i].dictRemark)
          }
        }
      }
    }).catch(error => {
      console.log(error)
    })
  },
  methods: {
    handleClose () {
      this.isShowAd = !this.isShowAd
    },
    format (percentage) {
      const vm = this
      return percentage === 100 ? 'X' : vm.timeshow + 's'
    },
    bannerChangeFtoor () {
      const vm = this
      vm.changeTime = ''
      vm.bannerChangeFoot = !vm.bannerChangeFoot
      if (vm.changeTimeFoot) {
        clearInterval(vm.changeTimeFoot)
        vm.changeTimeFoot = setInterval(function () {
          vm.bannerChangeFoot = !vm.bannerChangeFoot
        }, 5000)
      }
    },
    handleChange () {
      const vm = this
      vm.changeTime = ''
      vm.bannerChange = !vm.bannerChange
      if (vm.changeTime) {
        clearInterval(vm.changeTime)
        vm.changeTime = setInterval(function () {
          vm.bannerChange = !vm.bannerChange
          vm.bannerChangeFirt = true
        }, 5000)
      }
    },
    handleOpen () {
      window.open('https://www.wjx.cn/vj/mRgiO7S.aspx')
      this.dialogVisible = false
    },
    closeGg () {
      this.dialogVisible = false
    },
    handleTop () {
      document.documentElement.scrollTop = 0
    },
    loginFormOpened () {
      this.$refs.loginFormRef.openComponent()
    },
    loginFormClose () {
      this.$refs.loginFormRef.closeComponent()
    },
    handleShrink () {
      this.isShrink = !this.isShrink
    }
  },
  setup () {
    const isShowLogin = ref(false)
    const pathName = ref('')
    function loginEmit (val) {
      isShowLogin.value = true
    }
    function closeLoginEmit (val) {
      isShowLogin.value = false
    }
    return {
      loginEmit, closeLoginEmit, isShowLogin, pathName
    }
  }
}
</script>
<style>
  .dialog-style .el-dialog__body {
    max-height: none !important;
    display: flex;
    justify-content: center;
  }
  .dialog-style .el-dialog__close {
    color: #fff;
    font-size: 30px;
    z-index: 999999;
  }
</style>
<style scoped="scoped">
.change-btn {
  position: absolute;
  top: 50%;
  height: 50px;
  width: 50px;
  /* border: 1px solid #333; */
  border-radius: 50%;
  right: 90px;
  font-size: 30px;
  line-height: 50px;
  color: #333;
  /* background-color: #ff7b2b; */
  color: #fff;
  margin-top: 15px;
  z-index: 99;
}
/* .wraper {
  height: 100vh;
} */
.wraper {
  padding-bottom: 80px;
}
/deep/ .el-dialog {
  background: transparent;
  box-shadow: none;
}
/deep/ .el-dialog__body {
  padding: 0px
}
.ggClass .el-dialog__body {
  max-height: 90vh;
}
.toTop-content {
  z-index: 99999;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  width: 80px;
  position: fixed;
  bottom: 20%;
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  background-color: #fff;
  border-radius: 4px;
  z-index: 99999;
  transition: width 0.3s ease-in-out 0.3s;
}
.toTop-content.active {
  width: 0px;
  transition: width 0.3s ease-in-out 0.3s;
}
.toTop {
  visibility: none;
  transition: visibility 0.4s ease-in-out 0.4s;
}
.toTop.active {
  visibility:hidden;
  transition: visibility 0.2s ease-in-out 0.2s;
}
.el-icon-d-arrow-right {
  transform: rotate(0deg);
  transition: transform 0.2s ease-in-out 0.2s;
}
.el-icon-d-arrow-right.active {
  transform: rotate(180deg);
  transition: transform 0.2s ease-in-out 0.2s;
}
.span-to {
  color: #666666;
  width: 80px;
  font-size: 30px;
  height: 80px;
  line-height: 80px;
  cursor: default;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  border-bottom: 1px dashed #ccc;
}
.span-to:hover {
  background-color: #5D6FE9;
  color: #fff;
  border-radius: 4px;
}
.phone {
  animation:mymove 2s infinite;
  -webkit-animation:mymove 2s infinite; /*Safari and Chrome*/
  animation-direction:alternate;/*轮流反向播放动画。*/
  animation-timing-function: ease-in-out; /*动画的速度曲线*/
  -webkit-animation:mymove 2s infinite;
  -webkit-animation-direction:alternate;/*轮流反向播放动画。*/
  -webkit-animation-timing-function: ease-in-out; /*动画的速度曲线*/
}
@keyframes mymove
{
  0%{
  transform: scale(1);  /*开始为原始大小*/
  }
  25%{
      transform: scale(1.2); /*放大1.1倍*/
  }
  50%{
      transform: scale(1);
  }
  75%{
      transform: scale(1.2);
  }
}
.ad-content {
  display: flex;
  justify-content: center;
  position: fixed;
  z-index: 9;
  bottom: 0;
  margin: 0;
  padding: 0;
  width: 100%;
}
.swiper-li {
  width: 60px;
  height: 6px;
  background: #ccc;
  z-index: 99;
  border-radius: 5px;
  cursor: pointer;
}
.swiper-li.active{
  cursor: inherit;
  background: #ff7b2b;
}
.custom-progress {
  position: absolute;
  top: 0px;
  right: 30px;
  color: #fff;
  font-size: 30px;
  cursor: pointer;
}
/deep/ .custom-progress .el-progress__text{
    color: #fff;
    font-size: 14px !important;
    min-width: 40px;
  }
 /deep/ .custom-progress .el-progress-bar__outer {
    height: 2px;
  }
</style>
